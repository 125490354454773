<template>
    <div id="login">
        <div class="">
            <div id="login-row" class="row justify-content-center align-items-center">
                <div id="login-column" class="col-md-7 col-xs-12 p-0">
                    <div class="d-block d-sm-none">
                        <img id="logovh" src="../assets/mobile_vision.png" alt="Réussir 2023" class="m-0">
                    </div>

                    <div id="login-box" class="col-md-7 m-3 m-auto">
                        <form id="login-form" class="form p-5" action="" method="post">

                            <h3 class="title mb-5">Bienvenue au tirage de l'Année 2023</h3>
                            <div class="first-step" v-if="showFirstStep">
                                <div class="form-group mb-5">
                                    <label for="email" class="mb-2">Email</label><br>
                                    <!-- <input @keyup="checkIfUserIsRegistered" v-model="input.email" type="email" class="form-control" id="email" aria-describedby="emailHelp" > -->
                                    <input v-model="input.email" type="email" class="form-control" id="email" aria-describedby="emailHelp" >
                                </div>

                                <div class="btn wizard-btn mb-5" @click="checkIfUserIsRegistered">
                                    Valider
                                </div>
                            </div>

                            <!-- <div v-if="userExists" class="form-group mb-5">
                                <label for="phone_number" class="mb-2">N° Telephone</label><br>
                                <vue-tel-input @change="otpShow" v-model="phoneNumber" placeholder="Veuillez entrer un numéro de téléphone" id="phone_number"></vue-tel-input>
                                <div class="hint">Veuillez entrer votre numéro de téléphone afin de recevoir un code d'accès par email</div>
                            </div> -->

                            <div v-if="showOtp">
                                <div class="form-group">
                                    Bonjour {{ this.user.firstName }} {{ this.user.name }}, veuillez cliquer sur le bouton ci-dessous afin de voir votre verset de l'année 2023
                                </div>
                                <div class="form-group mb-5">
                                    <div v-if="!hideBtnVerset" @click="userSetVerse(user.id)" class="btn wizard-btn">Afficher mon verset 2023</div>
                                </div>
                            </div>
                            <form-wizard
                                    v-if="showRegister"
                                    prev="Précédent"
                                    next="Suivant"
                                    finish="Terminer"
                                    @on-complete="onComplete"

                            >

                                <tab-content
                                        title="Additional Info"
                                        :before-change="validateAsync"
                                >
                                    <h4 class="subtitle text-left mb-5">
                                        Remplissez vos informations afin de procéder au tirage de verset
                                    </h4>
                                    <div class="form-group mb-3">
                                        <label for="full_name" class="mb-2">Nom & prénoms</label><br>
                                        <input v-model="input.name" type="text" class="form-control" id="full_name" aria-describedby="nameHelp" >
                                    </div>

                                    <div class="form-group mb-5">
                                        <label for="phone_number" class="mb-2">N° Telephone</label><br>
                                        <vue-tel-input v-model="input.phoneNumber" placeholder="Veuillez entrer un numéro de téléphone" id="phone_number_new"></vue-tel-input>
                                    </div>
                                </tab-content>
                                <tab-content title="Last step">
                                    <h4 class="subtitle text-left mb-5">
                                        En vous inscrivant, vous aurez la possibilité d'accéder, chaque fois que vous le souhaitez aux versets que vous aurez tiré
                                    </h4>
                                    <div class="form-group mb-3">
                                        <label for="country" class="mb-2">Pays</label><br>
                                        <country-select searchable="true" required :autocomplete="true" v-model="input.country" :country="input.country" topCountry="CI" id="country" class="form-control vue-control-select"/>
                                    </div>

                                    <div class="form-group mb-3">
                                        <label for="city" class="mb-2">Ville</label><br>
                                        <input v-model="input.city" type="text" class="form-control" id="city" aria-describedby="cityHelp" >
                                    </div>
                                </tab-content>
                                <template slot="footer" slot-scope="props">
                                    <div class="wizard-footer-left">
                                        <wizard-button class="wizard-btn"  v-if="props.activeTabIndex > 0" @click="props.prevTab()" :style="props.fillButtonStyle">Précédent</wizard-button>
                                    </div>
                                    <div class="wizard-footer-right">
                                        <wizard-button v-if="props.isLastStep" @click="onComplete" class="wizard-footer-right wizard-btn finish-button" :style="props.fillButtonStyle">  {{props.isLastStep ? 'Terminer' : 'Next'}}</wizard-button>
                                        <wizard-button v-else @click="props.nextTab()" class="wizard-footer-right wizard-btn" :style="props.fillButtonStyle">Suivant</wizard-button>
                                    </div>
                                </template>
                            </form-wizard>

                            <div class="my-verse" v-if="showVerse">
                                <div class="form-group">
                                    Cher {{ this.user.firstName }} {{ this.user.name }}, voici votre verset pour réussir votre année 2023.
                                </div>
                                <div class="form-group">
                                    <img class="img-responsive" :src="verseImg" alt="Mon verset 2023">
                                </div>

                                <div class="mt-5">
                                    <a class="btn wizard-btn text-white" :href="verseImg" download="MonVerset2023">Télécharger mon verset</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="login-aside" class="col-md-5 d-none d-md-block">
                    <img id="aside_login" src="../assets/web_img.png" alt="Réussir 2023" class="m-0 w-100">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import API from '../API/Api.js'
    import {FormWizard, TabContent} from 'vue-form-wizard'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'

    import {VueTelInput} from 'vue-tel-input';
    import 'vue-tel-input/dist/vue-tel-input.css';

    import _ from 'lodash';


    export default {
        name: "Login",
        components: {
            FormWizard,
            TabContent,
            VueTelInput
        },
        data() {
            return {
                input: {
                    email: "",
                    name: "",
                    password: "JESUS IS LORD",
                    passwordConfirm: "",
                    country: "",
                    city: "",
                    phoneNumber: ""
                },
                phoneNumber: "",
                notSamePass: false,
                showRegister: false,
                userExists: false,
                showOtp: false,
                showVerse: false,
                verseImg: "",
                user: {},
                hideBtnVerset: false,
                showFirstStep: true
            }
        },
        mounted() {
            console.log( localStorage.getItem('accessToken') );
            /* if ( localStorage.getItem('accessToken') ) {
                this.$router.replace({ name: "dashboard" });
            } */

            console.log(this.input.phoneNumber)
            if (this.input.phoneNumber !== '')
                this.showOtp = true;
        },
        watch: {
            phoneNumber(newValue) {
                console.log(newValue)
                if (newValue !== '') {
                    this.showOtp = true;
                }
            }
        },
        methods: {
            // otp methods
            onComplete(){
                this.userRegister().then( )
            },

            userRegister() {
                if(this.input.username !== "" && this.input.password !== "") {
                    console.log('OK');
                    const myApi = new API();

                    console.log(this.input);
                    console.log (this.input.name.split(' ')[1]);
                    let userRegistrationData = this.input;

                    userRegistrationData.firstName = this.input.name.split(' ')[1];
                    userRegistrationData.name = this.input.name.split(' ')[0];

                    myApi.register(userRegistrationData).then( response => {
                        console.log(response)
                        if (typeof response.contextStatus !== "undefined" && response.contextStatus === 900) {
                            this.$toast.top(this.errorTranslator(response.response.data.violations[0].message));
                            return null;
                        } else {
                            // localStorage.setItem('accessToken', response.token);
                            console.log (this.$store);
                            this.$store.commit('currentUserSetting', response);
                            this.showFirstStep = false;

                            // provision user var
                            this.user = response;

                            // get the verse
                            this.userSetVerse(this.$store.state.user.current.id);
                        }

                    }).catch( (err) =>{
                        console.log(err);
                        this.$toast.top('Service indisponible. Veuillez réessayer ultérieurement', err);
                    });

                } else {
                    this.$toast.top('Service indisponible. Veuillez réessayer ultérieurement');
                }
            },

            userSetVerse(userId) {

                // display verse div
                this.showVerse = true;
                this.hideBtnVerset = true;

                // hiding elements
                this.showRegister = false;
                const myApi = new API();
                myApi.setVerse(userId).then( response => {
                    console.log(response);
                    if (typeof response.data !== "undefined") {
                        console.log('OK');
                        this.$store.commit('currentVerseSetting', response.data);
                        this.verseImg = this.$store.state.verse.current.path;
                    }
                });
            },

            onChangedHandler(lastEnteredCode){
                console.log("code changed",  lastEnteredCode);
                this.isCodeValid = true;
            },

            onPasteHandler(code){
                console.log("code pasted",  code);
            },
             otpShow: function () {
                 alert('OKç');
                 this.showOtp = true;
             },
             verifyUserExists: function() {
                 // If user exists
                 // this.userExists = true;

                 // if not
                 this.showRegister = true;
             },
             beforeTabSwitch: function(){
                alert("This is called before switchind tabs")
                return true;
             },
             passwordControl () {
                 console.log("Control", this);
                 console.log("Control", this.passwordConfirm);
                 console.log("Control", this.password);
                 if(this.input.passwordConfirm !== this.input.password) {
                     // comment
                     console.log(this);
                     this.notSamePass = true;
                     return true;
                 }
                 this.notSamePass = false;
                 console.log(this.notSamePass)
             },
             validateAsync:function() {

                return new Promise((resolve) => {
                    setTimeout(() => {
                        // this.checkIfUserIsRegistered();
                        resolve(true)
                    }, 1000)
                })
             },
             checkIfUserIsRegistered() {
                 const myApi = new API();
                 myApi.userExists(this.input.email).then( response => {
                     console.log(response)
                     if (typeof response.contextStatus !== "undefined" && response.contextStatus === "OK" && response.codeStatus === 200) {
                         // user existant
                         this.userExists = true;
                         this.showOtp = true;
                         this.showRegister = false;
                         this.user = response.data;

                         console.log(this.user)
                         // user ayant son verset de l'année
                         if (typeof this.user.verse !== "undefined" && this.user.verse.length > 0) {
                             console.log(new Date().getFullYear());
                             // const currentYear = new Date().getFullYear();
                             const currentYear = 2023;
                             let vm = this;
                             _.forEach(this.user.verse, function(value) {
                                 if (value.year === currentYear) {
                                     vm.verseImg = value.path;
                                     vm.showVerse = true;
                                     vm.showOtp = false;
                                     vm.showFirstStep = false;
                                     return true;
                                 }
                             });

                         }

                     } else {
                         // user inexistant
                         this.$toast.top("Cet email n'existe pas dans la base");
                         this.showRegister = true;
                         this.userExists = false;
                         this.showOtp = false;
                     }

                 }).catch( () =>{
                     this.$toast.top('Les services sont indisponibles pour le moment. Veuillez réessayer ultérieurement');
                 });
             },
             userRegistration() {
                 if(this.input.username !== "" && this.input.password !== "") {
                     console.log('OK');
                     const myApi = new API();

                     console.log(this.input);
                     console.log (this.input.name.split(' ')[1]);
                     let userRegistrationData = this.input;

                     userRegistrationData.firstName = this.input.name.split(' ')[1];
                     userRegistrationData.name = this.input.name.split(' ')[0];

                     myApi.register(userRegistrationData).then( response => {
                         console.log(response)
                         if (typeof response.contextStatus !== "undefined" && response.contextStatus === 900) {
                             this.$toast.top(this.errorTranslator(response.response.data.violations[0].message));
                             return null;
                         } else {
                             // localStorage.setItem('accessToken', response.token);
                             console.log (this.$store);
                             this.$store.commit('currentUserSetting', response);

                             // Do a login
                             console.log ('OT');
                             const mySubApi = new API();
                             console.log(mySubApi);
                             mySubApi.login(this.input.email, this.input.password).then( response => {
                                 console.log(response);
                                 this.$store.commit('loginStop', null);

                                 localStorage.setItem('accessToken', response.token);
                                 this.$store.commit('updateAccessToken', response.token);

                                 this.$router.replace({ name: "dashboard" });
                             }).catch( (error) =>{
                                 console.log(error);
                                 this.$toast.top('Email ou mot de passe incorrect');
                             });
                         }

                     }).catch( (err) =>{
                         console.log(err);
                         this.$toast.top('Email ou mot de passe incorrect', err);
                     });

                 }
             },
             login() {
                if(this.input.username !== "" && this.input.password !== "") {
                    console.log('OK');
                    const myApi = new API();
                    myApi.login(this.input.email, this.input.password).then( response => {
                        if (typeof response.contextStatus !== "undefined" && response.contextStatus === 900) {
                            this.$toast.top(this.errorTranslator(response.response.data.violations[0].message));
                            return null;
                        } else {
                            console.log(response)
                            // this.$emit("authenticated", true);

                            localStorage.setItem('accessToken', response.token);
                            console.log(this.$store);
                            this.$store.commit('loginStop', null);
                            this.$store.commit('updateAccessToken', response.token);

                            this.$router.replace({name: "dashboard"});
                        }

                    }).catch( () =>{
                        this.$toast.top('Email ou mot de passe incorrect');
                    });

                } else {
                    this.$toast.top('Email ou mot de passe incorrect');
                }
            }

        }
    }
</script>

<style scoped>
    #login {
        background-size: cover;
        height: 100vh;
    }

    #logovh {
        width: 100%;
    }

    .field-danger {
        border: 1px solid #dc3545;
        color: #dc3545;
        background: #f3c7c8;
    }

    #otp {
        gap: 5% !important;
    }

    #otp input {
        border: solid 1px #000 !important;
    }

</style>
